/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useState } from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {MdOutlineWorkOutline} from 'react-icons/md'
import {BiMessageSquareDetail} from 'react-icons/bi'

function Nav() {

  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav>
      <a href="#" 
        className={activeNav === '#' ? 'active' : ''}
        onClick={() => setActiveNav('#')}>
        <AiOutlineHome />
      </a>
      <a href="#about" 
        onClick={() => setActiveNav('#about')}
        className={activeNav === '#about' ? 'active' : ''}>
        <AiOutlineUser />
      </a>
      <a href="#experience" 
        onClick={() => setActiveNav('#experience')} 
        className={activeNav === '#experience' ? 'active' : ''}>
        <BiBook />
      </a>
      <a href="#portfolio" 
        onClick={() => setActiveNav('#portfolio')} 
        className={activeNav === '#portfolio' ? 'active' : ''}>
        <MdOutlineWorkOutline />
      </a>
      <a href="#contact" 
        onClick={() => setActiveNav('#contact')} 
        className={activeNav === '#contact' ? 'active' : ''}>
        <BiMessageSquareDetail />
      </a>
    </nav>
  )
}

export default Nav
